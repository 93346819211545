import React, { useState } from 'react';
import { Link } from 'gatsby';
import Illustration from '../assets/00000.png';
import Video from '../assets/Comp 2_110920.webm';
import Check from '../assets/check.svg';
import Exclamation from '../assets/exclamation.svg';
import './index.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(true);

  function signUp() {
    if (!email) {
      return;
    }

    setLoading(true);
    fetch(
      'https://mailchimp7bxnaabl-subscribego.functions.fnc.fr-par.scw.cloud',
      {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setFailure(true);
        setLoading(false);
      });
  }

  setTimeout(() => {
    setLoadingVideo(false);
  }, 2000);

  return (
    <>
      {loadingVideo && (
        <div
          style={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div id="loader"></div>
        </div>
      )}
      <Layout
        style={{
          opacity: loadingVideo ? 0 : 1,
        }}
      >
        <SEO title="Inheaden Connect" />
        <div className="container">
          <div id="content">
            <h1>
              <span>{t('Connect')}. </span>
              <span>{t('Create')}. </span>
              <span>{t('Collaborate')}. </span>
            </h1>
            <p>{t('Subtitle')}</p>
            {success || failure ? (
              <>
                {success && (
                  <div className="success">
                    <img src={Check} />
                    {t('Thank you')}
                  </div>
                )}
                {failure && (
                  <div className="failure">
                    <img src={Exclamation} />
                    {t('Failure')}
                  </div>
                )}
              </>
            ) : (
              <form onSubmit={signUp} action="javascript:void(0);">
                <input
                  placeholder={t('Enter your Email Address')}
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" disabled={loading}>
                  {t('Notify me')}
                </button>
                <small>{t('Newsletter Disclaimer')}</small>
              </form>
            )}
          </div>
          <div id="image">
            <video
              autoPlay
              loop
              muted
              onError={() => {
                setLoadingVideo(false);
              }}
              onLoadedData={() => {
                setLoadingVideo(false);
              }}
              poster={Illustration}
            >
              <source src={Video} type='video/webm; codecs="vp8, vorbis"' />
            </video>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
